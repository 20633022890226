<template>
  <div class="install-invalid p-4">
    <h5>Oops! It looks like the link you clicked is expired or not valid.</h5>
    <CButton
      color="info"
      shape="square"
      href="https://www.postengine.com/install"
      class="my-4"
    >
      Request a New Link
    </CButton>
    <h5>
      Or email
      <CButton
        class="help-link"
        color="link"
        href="mailto:help@rooof.com"
      >
        help@rooof.com
      </CButton>
    </h5>
  </div>
</template>

<script>
export default {
  name: 'InstallInvalid'
}
</script>

<style lang="scss" scoped>
.install-invalid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.help-link {
  padding: 0;
  color: $info;
  font-size: inherit;
}
</style>
